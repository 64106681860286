<template>
  <div class="dashboard-container" />
</template>

<script>
import { mapMutations } from 'vuex'
import store from '../store'

/**
 * 记录访问，只有页面刷新或者第一次加载才会记录
 */
let menuUrl = ''
let yuanTotalMenuUrl = ''
export default {
  name: 'Dashboard',
  components: {},
  watch: {
    $route: {
      handler: function (route) {
        const code = route.query && route.query.code
        if (code !== undefined) {
          store.getters.userInfo.unifiedPortal = true
          this.login(code)
        }
      },
      immediate: true
    }
  },
  methods: {
    login(code) {
      const params = {
        code: code,
        redirectUri: window.location.href,
        applicationName: 'client'
      }
      this.$store.dispatch('UnifiedPortalLogin', params).then((res) => {
        if (res.props.target_value !== null && res.props.target_value !== undefined) {
          const menu = store.getters.addRouters
          menu.forEach((element) => {
            if (element.children !== undefined) {
              yuanTotalMenuUrl = element.path
              this.forEachMenu(element.children, res.props.target_value, yuanTotalMenuUrl)
            }
            if (menuUrl === '' && menuUrl === undefined) {
              menuUrl = element.path
            }
          })
          if (menuUrl !== '' && menuUrl !== undefined) {
            this.$router.push({ path: menuUrl })
          } else {
            this.setNeedFindMenus(true)
            this.$router.push({ path: res.props.target_value })
          }
        } else {
          this.$router.push({ path: '/home' })
        }
      })
    },
    forEachMenu(children, targetValue, totalMenuUrl) {
      for (let i = 0; i < children.length; i++) {
        let totalMenuUrl1 = ''
        totalMenuUrl1 = totalMenuUrl
        const element = children[i]
        if (element.children !== undefined) {
          totalMenuUrl1 = totalMenuUrl1 + '/' + element.path
          this.forEachMenu(element.children, targetValue, totalMenuUrl1)
        } else if (element.id === targetValue) {
          menuUrl = totalMenuUrl1 + '/' + element.path
          break
        } else {
          totalMenuUrl1 = yuanTotalMenuUrl
        }
      }
    },
    ...mapMutations({
      setNeedFindMenus: 'setNeedFindMenus'
    })
  }
}
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
.dashboard-editor-container {
  padding: 18px 22px 22px 22px;
  background-color: rgb(240, 242, 245);
  .chart-wrapper {
    background: #fff;
    padding: 16px 16px 0;
    margin-bottom: 32px;
  }
}
</style>
